<template>
  <div class="imgPlaceholder">
    <img
      v-if="blur && placeholder"
      :src="placeholder"
      class="image"
      :class="{
        blur: this.blur,
      }"
      :style="imgStyle"
      @load="loadImage"
      @click="$emit('click')"
    />
    <img v-else-if="placeholder && !loaded" :src="placeholder" class="image" @load="loadImage" :style="imgStyle" />

    <img v-if="loaded" :src="src" class="image full" :style="imgStyle" />
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    blur: {
      type: Boolean,
      default: false,
    },
    w: {
      type: Number,
      default: null,
    },
    h: {
      type: Number,
      default: null,
    },
    ratio: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    imgStyle() {
      // add aspect ratio style if both w and h are set
      var styles = {};
      if (this.ratio) {
        styles = {
          "aspect-ratio": this.ratio,
          // / this.w) * 100}%`,
        };
      } else if (this.w && this.h) {
        var ratio = this.h / this.w;
        styles = {
          "aspect-ratio": `${this.h} / ${this.w}`,
          // / this.w) * 100}%`,
        };
      }
      return styles;
    },
  },
  watch: {
    src() {
      this.loadImage();
    },
  },
  methods: {
    loadImage() {
      this.loaded = false;
      const img = new Image();
      img.src = this.src;
      img.onload = () => {
        this.loaded = true;
        // this.$emit("loaded");
      };
    },
  },
  mounted() {
    if (!this.placeholder) {
      this.loadImage();
    }
  },
};
</script>

<style scoped>
.imgPlaceholder {
  width: 100%;
  position: relative;
}

.image {
  width: 100%;
  max-width: none;
}

.blur {
  filter: blur(10px);
}
</style>
