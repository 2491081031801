<template>
  <div class="container is-fluid">
    <!-- Include the ImagePopup component -->
    <ImagePopup ref="imagePopup" />
    <div class="columns">
      <!-- Column 1 
      <div class="column is-3">
        <div class="list is-hoverable">
          <a class="list-item" v-for="(template, index) in templates" :key="index" @click="selectedTemplate = template">
            <figure class="image is-48x48">
              <img :src="template.avatar" alt="" />
            </figure>
            {{ template.name }}
          </a>
        </div>
      </div>-->
      <!-- Column 2 -->
      <div class="column is-5-fullhd is-6-desktop">
        <div class="stickyForm">
          <b-dropdown v-model="selectedTemplate" aria-role="list">
            <template v-if="hasATemplateSelected" #trigger>
              <b-button
                :label="selectedTemplateName"
                type="is-info is-light is-active is-small"
                icon-left="globe"
                icon-right="menu-down"
                icon-pack="fal"
              />
            </template>

            <template v-else #trigger>
              <b-button
                label="Image Templates"
                type="is-n  is-info is-light  is-small"
                icon-left="account-multiple"
                icon-right="menu-down"
              />
            </template>

            <b-dropdown-item :value="null" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" icon="earth"></b-icon>
                <div class="media-content">
                  <h3>No templates</h3>
                  <small>Everyone can see</small>
                </div>
              </div>
            </b-dropdown-item>

            <b-dropdown-item :value="'painting'" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" icon="account-multiple"></b-icon>
                <div class="media-content">
                  <h3>Ugly painting</h3>
                  <small>Only friends can see</small>
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <br />
          <br />

          <!--  
          <b-field position="is-centered">
            <b-input placeholder="Search..." type="search" icon="magnify"> </b-input>
            <p class="control">
              <b-button label="Search" type="is-info" />
            </p>
          </b-field> -->

          <div class="field">
            <label class="label">
              <span v-if="promptMode == 'basic'">Prompt </span>
              <span v-else-if="promptMode == 'prefix'">Subject </span>
              <span v-else>Input </span>
            </label>
            <div class="control">
              <textarea class="textarea" v-model="promptInput" :placeholder="promptPlaceholder"></textarea>
            </div>
          </div>

          <div class="field" v-if="promptMode == 'prefix'">
            <label class="label">
              <span>Prefix </span>
            </label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="prefix"
                placeholder="Cubist painting in the style of picasso,  modern painting, cubism, museum scan"
              ></textarea>
            </div>
          </div>

          <div class="field" v-if="promptMode == 'remix'">
            <label class="label">
              <span>Instructions </span>
            </label>
            <div class="control">
              <textarea
                class="textarea"
                v-model="remix"
                placeholder="Select a famous movie director, describe the scene and subject in details, elements the background, the framing, and etc. "
              ></textarea>
            </div>
            <b-button @click="previewGptPrompt" type="is-warning is-light is-small" icon-left="play" icon-pack="far"
              >Preview prompt</b-button
            >
            <div class="content">
              <quote class="quote code small generatedPromptPreview">
                aaa
                {{ generatedPromptPreview }}
              </quote>
            </div>
          </div>

          <div class="row">
            <div class="left">
              <div class="field"></div>

              <b-dropdown v-model="promptMode" aria-role="list">
                <template #trigger>
                  <b-button icon-pack="far" type="is-text" rounded :key="promptMode + 'Fsd'">
                    <i :class="currentpromptMode.icon" aria-hidden="true"></i>
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="stat in promptModeOptions"
                  :key="stat.value + 99998"
                  :value="stat.value"
                  aria-role="listitem"
                >
                  <div class="media">
                    <i :class="stat.icon" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>{{ stat.label }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>

              <!--   -->

              <b-button
                @click="showAdvanced = !showAdvanced"
                icon-pack="far"
                type="is-text"
                rounded
                :class="{
                  'is-active': showAdvanced,
                }"
              >
                <i class="fas fa-sliders-h" aria-hidden="true"></i>
              </b-button>

              <!-- 

                       <b-button @click="$emit('prev')" icon-pack="far" type="is-text" rounded :disabled="disablePrev">
                <i class="fas fa-portrait" aria-hidden="true"></i>
              </b-button>

              
              <b-button @click="$emit('prev')" icon-pack="far" type="is-textNO" rounded :disabled="disablePrev">
                4x
              </b-button>

               -->

              <b-dropdown v-model="nbImages" aria-role="list">
                <template v-if="nbImages == 1" #trigger>
                  <b-button icon-pack="far" type="is-text" rounded key="dasu8">
                    <i class="fas fa-square" aria-hidden="true"></i>
                  </b-button>
                </template>

                <template v-else-if="nbImages == 9" #trigger>
                  <b-button icon-pack="far" type="is-text" rounded key="da444su8">
                    <i class="fas fa-th" aria-hidden="true"></i>
                  </b-button>
                </template>

                <template v-else #trigger>
                  <b-button icon-pack="far" type="is-text" rounded key="dasu668">
                    <i class="fas fa-th-large" aria-hidden="true"></i>
                  </b-button>
                </template>

                <b-dropdown-item :value="1" aria-role="listitem">
                  <div class="media">
                    <i class="fas fa-square" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>One image</h3>
                      <!--
                      <small>Everyone can see</small>  -->
                    </div>
                  </div>
                </b-dropdown-item>

                <b-dropdown-item :value="4" aria-role="listitem">
                  <div class="media">
                    <i class="fas fa-th-large" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>4 images</h3>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item :value="9" aria-role="listitem">
                  <div class="media">
                    <i class="fas fa-th" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>9 images</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>

              <b-dropdown v-model="postVisibility" aria-role="list">
                <template #trigger>
                  <b-button icon-pack="far" type="is-text" rounded :key="currentPostVisibility.value + 'Fsd'">
                    <i :class="currentPostVisibility.icon" aria-hidden="true"></i>
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="stat in postVisibilityOptions"
                  :key="stat.value + 99998"
                  :value="stat.value"
                  aria-role="listitem"
                >
                  <div class="media">
                    <i :class="stat.icon" aria-hidden="true"></i>
                    <div class="media-content">
                      <h3>{{ stat.label }}</h3>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="control right">
              <div>
                <b-button
                  class="button is-dark"
                  icon-lib="fas"
                  icon-right="arrow-right"
                  @click="generate"
                  :disabled="!canSubmit"
                  :loading="generating"
                >
                  Generate
                </b-button>

                <!--  
                <br />
                <div class="control" style="margin-top: 5px">
                  <b-switch
                    v-model="isDraft"
                    size="is-small"
                    type="is-success"
                    true-label="Draft"
                    false-label="Published"
                  ></b-switch>
                  Private draft
                </div>-->
              </div>
            </div>
          </div>

          <div class="box advanced" v-show="showAdvanced">
            <!-- Models Dropdown -->
            <h3 class="title is-5"><i class="far fa-sliders-h"></i> Advanced options</h3>

            <br />

            <b-field label="" class="is-flex is-justify-content-space-between">
              <b-slider
                v-model="ratioIndex"
                :custom-formatter="(val) => ratios[val].label"
                :min="0"
                :max="8"
                :step="1"
                type="is-dark"
                ticks
              >
                <b-slider-tick v-for="(r, i) in ratios" :key="i + 'fds'" :value="i" :label="r.label">
                  {{ r.label }}
                </b-slider-tick>
              </b-slider>
            </b-field>
            <br />
            <br />

            <b-field label="Model" class="has-label-fixed">
              <b-select v-model="selectedModel">
                <option v-for="model in models" :key="model.id" :value="model.id" :disabled="model.disabled">
                  <span> <i :class="model.icon"></i> {{ model.label }} </span>
                </option>
              </b-select>
            </b-field>

            <div class="sd-controls" v-if="selectedModel.startsWith('sd')">
              <!-- Guidance input -->
              <b-field label="Guidance" class="is-flex is-justify-content-space-between">
                <b-input type="number" min="1" max="30" step="1" v-model="guidance"></b-input>
                <b-slider v-model="guidance" :min="1" :max="30" :step="1"></b-slider>
              </b-field>

              <!-- Quality input -->
              <b-field label="Quality (steps)" class="is-flex is-justify-content-space-between">
                <b-input type="number" min="10" max="150" v-model="steps"></b-input>
                <b-slider v-model="steps" :min="10" :max="150" :step="1"></b-slider>
              </b-field>

              <!-- Schedulers Dropdown -->
              <b-field label="Scheduler" class="has-label-fixed">
                <b-select v-model="selectedScheduler">
                  <option v-for="schedulers in schedulers" :key="schedulers.id" :value="schedulers.id">
                    <span> <i :class="schedulers.icon"></i> {{ schedulers.label || schedulers.id }} </span>
                  </option>
                </b-select>
              </b-field>

              <div class="field">
                <label class="label">
                  <span>Negative prompt </span>
                </label>
                <div class="control">
                  <textarea
                    class="textarea"
                    v-model="netativePrompt"
                    placeholder="Ugly, watermark, low quality, blurry, low resolution, low res "
                  ></textarea>
                </div>
              </div>
            </div>

            <!-- Seed input -->
            <b-field label="Seed" v-show="selectedModel != 'dalle'">
              <b-input type="number" placeholder="Random"></b-input>
            </b-field>
          </div>
        </div>
      </div>
      <!-- Column 3 -->
      <div class="column">
        <div class="history">
          <div v-for="h in history" :key="h.id + h.tempId" class="item">
            <!-- prompt and image under-->
            <div class="columns">
              <div class="column">
                <h1 class="title is-5">
                  {{ h.imagePrompt }}

                  <b-button @click="reusePrompt(h)" type="is-small" outlined> reuse</b-button>
                </h1>

                <div class="box" v-if="h.moreInfoVisible">
                  <table class="table">
                    <tr>
                      <th>Template</th>
                      <td>
                        <span class="tag is-light">{{ h.imagePromptTemplate || "None" }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Prompt</th>
                      <td>
                        <span class="tag is-light">{{ h.imagePrompt }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>Model</th>
                      <td>
                        <span class="tag is-danger">{{ h.imageEngine }}</span>
                      </td>
                    </tr>
                    <tr>
                      <th>When</th>
                      <td>
                        <span class="tag is-light"
                          ><timeago :datetime="new Date(h.created)" :auto-update="0"></timeago
                        ></span>
                      </td>
                    </tr>
                  </table>
                  <json-debug :data="h" />
                </div>

                <span class="tag is-warning" v-show="h.status"> {{ h.status }} </span>
                <LoadingSpinner v-if="h.status == 'pending'" />
              </div>
              <div class="column is-narrow"><DotDrop :items="h.menuItems" position="is-bottom-left" /></div>
            </div>

            <div
              class="grid-container"
              :class="{
                one: h.images.length == 1,
                many: h.images.length > 4,
              }"
            >
              <div v-for="image in h.images" :key="image.url" class="grid-item" @click="showImagePopup(image.url)">
                <imgPlaceholder
                  :src="image.url"
                  placeholder="https://placehold.co/30x30"
                  :ratio="image.ratio"
                  :w="image.width"
                  :h="image.height"
                />
                <!-- 
                <img :src="image.url" loading="lazy " @click="showImagePopup(image.url)" /> -->
              </div>
            </div>
          </div>
          <!-- 
                 <div class="images">
              <figure class="image" v-for="i in h.images" :key="i.url">
                <img :src="i.url" alt="Post Image" />
              </figure>
            </div>
            
          <figure class="image">
            <img :src="imageResult" alt="Result" />
          </figure> -->
          <json-debug :data="history" />
        </div>
      </div>
    </div>
    <json-debug :data="options" />
  </div>
</template>

<script>
import jsonDebug from "../e/jsonDebug.vue";
import DotDrop from "@/components/e/DotDrop.vue";

import ImagePopup from "@/components/studio/imagePopup.vue";

import LoadingSpinner from "@/components/e/LoadingSpinner.vue";
import imgPlaceholder from "@/components/e/imgPlaceholder.vue";
var DUMMY_HISTORY = [
  {
    date: "2021-05-01",
    prompt: "A lion in the savannah",
    negativePrompt: "Ugly, watermark, low quality, blurry, low resolution, low res ",
    images: [
      {
        url: "https://via.placeholder.com/600",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600",
        ratio: 1.5,
      },
      {
        url: "https://via.placeholder.com/600",
        ratio: 1.5,
      },
    ],
  },
];

export default {
  components: { DotDrop, ImagePopup, LoadingSpinner, imgPlaceholder },
  data() {
    return {
      templates: [
        { name: "No template", avatar: "https://via.placeholder.com/48" },
        // add more templates here
      ],
      historyData: DUMMY_HISTORY,
      generating: false,
      selectedTemplate: null,
      promptInput: "",
      netativePrompt: "",
      imageResult: "", // update this when "generate" button is clicked
      showAdvanced: false,
      nbImages: 4,
      isDraft: false,
      selectedModel: "sdxl",
      models: [
        { id: "sdxl", label: "SD XL", icon: "fa fa-truck" },
        { id: "sd", label: "SD", icon: "fa fa-truck" },
        { id: "dalle", label: "Dalle", icon: "fa fa-car" },

        { id: "mj", label: "Midjourney", icon: "fa fa-truck", disabled: false },
        // Other models...
      ],
      schedulers: [
        { id: "auto", label: "Auto", icon: "fa fa-code" }, // KarrasDPM is best
        { id: "DDIM", icon: "fa fa-code" },
        { id: "DPMSolverMultistep", icon: "fa fa-code" },
        { id: "HeunDiscrete", icon: "fa fa-code" },
        { id: "KarrasDPM", icon: "fa fa-code" },
        { id: "K_EULER_ANCESTRAL", icon: "fa fa-code" },
        { id: "K_EULER", icon: "fa fa-code" },
        { id: "PNDM", icon: "fa fa-code" },
      ],
      selectedScheduler: "auto", // "KarrasDPM",
      postVisibility: "public",
      postVisibilityOptions: [
        { label: "Public post", value: "public", icon: "fas fa-eye" },
        { label: "Private draft", value: "draft", icon: "fas fa-lock" },
      ],

      promptMode: "basic",
      promptModeOptions: [
        { label: "Full prompt", value: "basic", icon: "fas fa-pencil" },
        { label: "Prefixed prompt", value: "prefix", icon: "fas fa-puzzle-piece" },
        { label: "Expand with GPT ", value: "remix", icon: "fas fa-flask" },
      ],

      ratiosData: [
        {
          w: 21,
          h: 9,
        },
        {
          w: 16,
          h: 9,
        },
        {
          w: 3,
          h: 2,
        },
        {
          w: 5,
          h: 4,
        },
        {
          w: 1,
          h: 1,
        },
        {
          w: 4,
          h: 5,
        },
        {
          w: 2,
          h: 3,
        },
        {
          w: 9,
          h: 16,
        },
        {
          w: 9,
          h: 21,
        },
      ],
      ratioIndex: 4,

      //SD stuff
      steps: 40,
      guidance: 50,
    };
  },
  created() {
    // Select the "No template" by default
    this.selectedTemplate = this.templates[0];
  },
  computed: {
    botId() {
      return this.$route.params.bot;
    },
    prompt() {
      var mode = this.promptMode;
      if (mode == "prefix") {
        return this.prefix + ", " + this.promptInput;
      } else if (mode == "remix") {
        return this.promptInput;
      } else {
        return this.promptInput;
      }
    },
    canSubmit() {
      if (this.promptInput.length < 2) {
        return false;
      }
      return true;
    },
    hasATemplateSelected() {
      return this.selectedTemplate !== null;
    },
    selectedTemplateName() {
      return this.selectedTemplate.name;
    },
    currentPostVisibility() {
      return this.postVisibilityOptions.find((o) => o.value === this.postVisibility);
    },
    currentpromptMode() {
      return this.promptModeOptions.find((o) => o.value === this.promptMode);
    },
    promptPlaceholder() {
      if (this.promptMode == "prefix") {
        return "A lion in the savannah";
      } else if (this.promptMode == "remix") {
        return "An angry turtle ";
      } else {
        return "A gentle robot farming in the countryside";
      }
    },
    ratios() {
      return this.ratiosData.map((r, i) => {
        return {
          ...r,
          label: `${r.w}:${r.h}`,
          value: i,
          ratio: r.w / r.h,
        };
      });
    },
    ratio() {
      return this.ratios[this.ratioIndex];
    },
    history() {
      return this.historyData.map((h) => {
        return {
          ...h,
          menuItems: [
            {
              t: "Delete",
              icon: "far fa-trash",
              // hidden: !this.isOwner,
              click: () => {
                // alert(324235);

                this.$buefy.dialog.confirm({
                  message: "Delete this post?",
                  type: "is-danger",
                  onConfirm: () => {
                    window.API.editBotPost(this.botId, h.id, { deleted: true }).then((res) => {
                      this.$buefy.snackbar.open({
                        message: "Post deleted",
                        type: "is-warning",
                        position: "is-bottom",
                        actionText: "Close",
                        indefinite: false,
                      });
                      this.item = null;
                    });

                    //this.$buefy.toast.open('User confirmed')
                  },
                });
              },
            },
            {
              t: this.draft ? "Publish" : "Edit...",
              icon: "far fa-edit",
              //hidden: !this.isOwner,
              to: h.url + "/edit",
            },
            //view post
            {
              t: "View post",
              icon: "far fa-eye",
              to: h.url,
              hidden: this.draft,
            },

            // view more info button, toggle this for the entry  moreInfoVisible
            {
              t: "More info",
              icon: "far fa-info",
              click: () => {
                // h.moreInfoVisible = true;
                this.$set(h, "moreInfoVisible", true);
              },
            },

            {
              t: "Download images",
              icon: "far fa-download",
              click: () => {
                this.downloadImages(h.images);
              },
            },
          ],
          images: h.images.map((i) => {
            var ratio = i.ratio || i.w / i.h || 1;
            return {
              ...i,
              ratio: ratio,
            };
          }),
        };
      });
    },
    options() {
      return {
        nbImages: this.nbImages,
        prompt: this.prompt,
        negativePrompt: this.netativePrompt,
        model: this.selectedModel,
        scheduler: this.selectedScheduler,
        seed: this.seed,
        guidance: this.guidance,
        steps: this.steps,
        // quality: this.quality,
        //  postVisibility: this.postVisibility,,
        draft: this.postVisibility == "private",
        created: new Date(),
        promptMode: this.promptMode,
      };
    },
  },
  mounted() {
    this.loadHistory();
  },

  methods: {
    generate() {
      // Logic for generating the result based on selectedTemplate and prompt
      // Update imageResult here
      var opt = this.options;
      // this.$emit("generate", opt);
      this.generating = true;
      //turn off after one second no matter what
      setTimeout(() => {
        this.generating = false;
      }, 2000);

      // append the job,
      const tempId = Date.now(); // temporary ID
      var newJob = {
        tempId: tempId,
        botId: this.botId,
        imagePrompt: this.prompt,
        negativePrompt: this.netativePrompt,
        images: [],
        status: "pending",
        imgsLoaded: false,
        date: new Date(),
        imageEngine: this.selectedModel,
      };

      // add dummy images, based on the nbImages quantity
      var currentRatio = this.ratio.ratio || 1;
      for (let i = 0; i < this.nbImages; i++) {
        newJob.images.push({
          url: "https://via.placeholder.com/50",
          ratio: currentRatio,
          // width: 50,
          //height: 150,
        });
      }

      this.historyData.unshift(newJob);

      window.API.generateBotImage(this.botId, opt).then((res) => {
        console.log(">>generateBotImagePost res >> ", res);
        // this.redirectSuccess(res.postId);

        this.generating = false;
        //mark the job as completed...
        //newJob.status = "completed";
        // newJob.images = res.images;
        this.replaceHistoryItemByTempId(tempId, res.post);
      });
    },
    replaceHistoryItemByTempId(tempId, newData) {
      const itemIndex = this.historyData.findIndex((item) => item.tempId === tempId);

      console.log(newData, "newData");
      if (itemIndex !== -1) {
        newData.status = "loaded";
        //  this.historyData[itemIndex] = newData;
        this.historyData.splice(itemIndex, 1, newData);
        //    alert(itemIndex);
        /*
        .splice(itemIndex, 1, {
          ...newData,
          status: "loaded",
        });*/
      }
    },
    previewGptPrompt() {
      // Logic for previewing the prompt
    },
    loadHistory() {
      // Logic for loading the history
      window.API.getBotImageJobs(this.botId).then((res) => {
        console.log(">>getBotImageJobs res >> ", res);
        this.historyData = res;
      });
    },
    reusePrompt(h) {
      // Logic for reusing the prompt
      this.promptInput = h.imagePrompt;
    },
    async downloadImages(uuu) {
      // Assuming you have an array of image URLs
      /*
      const imageUrls = [
        "https://example.com/image1.jpg",
        "https://example.com/image2.jpg",
        // ... add more URLs as needed
      ];
*/
      const imageUrls = uuu.map((i) => i.url);
      for (let i = 0; i < imageUrls.length; i++) {
        const imageUrl = imageUrls[i];
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Extract image file name from URL or create one if not available
        const fileName = imageUrl.split("/").pop() || `image${i + 1}.jpg`;

        const url = URL.createObjectURL(blob);

        // Create an anchor tag dynamically and click it to download the image
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the Blob URL and removing the anchor tag
        URL.revokeObjectURL(url);
        document.body.removeChild(a);

        // This delay is to avoid some browsers' pop-up blockers
        await new Promise((resolve) => setTimeout(resolve, 100));
      }
    },
    showImagePopup(src) {
      this.$refs.imagePopup.open(src);
    },
  },
};
</script>

<style scoped>
.stickyForm {
  position: sticky;
  top: 94px;
}
.right {
  display: flex;
  justify-content: flex-end;
}
.advanced {
  margin-top: 40px;
  max-width: 400px;
}
.container.is-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row {
  display: flex;
  justify-content: space-between;
}

.generatedPromptPreview {
  font-style: italic;
  opacity: 0.5;
}

/* historyè */

.history .item {
  margin-bottom: 3em;
}
.images {
  display: flex;
  justify-content: space-evenly;
  flex-direction: row;
  align-content: space-between;
}
.images .image {
  margin-right: 5px;
}

/* img grid */
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px; /* Adjust this value for spacing between images */
}
.grid-container.one {
  grid-template-columns: repeat(1, 1fr);
}
.grid-container.many {
  grid-template-columns: repeat(3, 1fr);
}

.grid-item img {
  width: 100%;
  height: auto;
  display: block;
}
</style>
