<template>
  <div class="loading-spinner">
    <i class="fa fa-spinner fa-lg fa-spin"></i>
  </div>
</template>

<script>
export default {
  name: "LoadingSpinner",
};
</script>

<style scoped>
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.loading-spinner .fa-spinner {
  font-size: 50px;
  opacity: 0.3;
}
</style>
